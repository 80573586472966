import CryptoJS from 'crypto-js'
export default {
  getivstring(e) {
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
  },
  // 加密
  encrypt(word, keyStr, ivStr) {
    const key = CryptoJS.enc.Latin1.parse(keyStr);
    const iv = CryptoJS.enc.Latin1.parse(ivStr);
    const encoded = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding
    }).toString()
    return encoded;
  },
  encryptv2(word, keyStr) {
    const ivStr = this.getivstring(16)
    const key = CryptoJS.enc.Latin1.parse(keyStr);
    const iv = CryptoJS.enc.Latin1.parse(ivStr);
    const encoded = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding
    }).toString()
    const data = {
      text: encoded,
      iv: ivStr
    }
    return data;
  },
  // 解密
  decrypt(word, keyStr, ivStr) {
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);

    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  },
};