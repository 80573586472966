<template>
  <div id="app">
    <div class="header">
      <a href="https://aic.xin">
        <img src="@/assets/mainlogo_darkgree.png" />
      </a>
    </div>
    <el-row>
      <el-col :md="6" :sm="24"><div class="row-bg"></div></el-col>
      <el-col :md="12" :sm="24" v-if="!regsuccess">
        <div class="main">
          <el-form
            label-position="right"
            label-width="80px"
            :model="form"
            status-icon
            :rules="rules"
            ref="form"
          >
            <el-form-item label="手机号" prop="phonenumber">
              <el-row>
                <el-col :md="8" :sm="24">
                  <el-select
                    v-model="form.region"
                    placeholder="请选择国家/地区"
                  >
                    <el-option
                      v-for="item in regionlist"
                      :key="item.region"
                      :label="item.region"
                      :value="item.region"
                      :disabled="item.disable"
                    >
                      <span style="float: left">{{ item.region }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.text }}</span
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :md="16" :sm="24">
                  <el-input
                    v-model="form.phone"
                    placeholder="请输入手机号"
                    type="tel"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="form.username"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pass">
              <el-input
                type="password"
                v-model="form.pass"
                autocomplete="off"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input
                type="password"
                v-model="form.checkPass"
                autocomplete="off"
                placeholder="请确认输入的密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-row>
                <el-col :span="12">
                  <el-input
                    v-model="form.captchacode"
                    placeholder="请输入图形验证码"
                  ></el-input>
                </el-col>
                <el-col :span="12">
                  <el-image
                    :src="captcha.base64"
                    @click="getCaptcha"
                    v-loading="captchaloading"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="短信验证">
              <el-row>
                <el-col :span="16">
                  <el-input
                    v-model="form.smscode"
                    placeholder="请输入短信验证码"
                    v-if="!smssendstatus"
                    disabled
                  ></el-input>
                  <el-input
                    v-model="form.smscode"
                    placeholder="请输入短信验证码"
                    v-else
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="primary"
                    v-if="!smssendstatus"
                    @click="sendSmsCode()"
                    >发送短信</el-button
                  >
                  <el-button type="primary" v-else disabled>{{
                    sendsmscoutdown
                  }}</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">注册</el-button>
            </el-form-item>
            <!-- <el-form-item>
              注册即同意《激萌信息隐私策略》
            </el-form-item> -->
          </el-form>
        </div>
      </el-col>
      <el-col :md="12" :sm="24" v-else>
        <el-result
          icon="success"
          title="注册成功"
          subTitle="您的账户注册申请已通过并处理完成点击返回返回上一步继续操作"
        >
          <template slot="extra">
            <el-button type="primary" size="medium" @click="regsuccessbutton"
              >返回</el-button
            >
          </template>
        </el-result>
      </el-col>
      <el-col :md="6" :sm="24"><div class="row-bg"></div></el-col>
    </el-row>
    <footer class="footer">
      <p>© 2016~2022 Shijiazhuang MOE Information Technology Co., Ltd</p>
      <p>© 2015~2022 AICO Group Limited company</p>
      <p>© 2022 AICO CLOUD UK LTD</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >冀ICP备15001777号</a
        >
        |
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010402001382"
          target="_blank"
          >冀公网安备 13010402001382号</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        var ls = 0;
        if (this.form.pass !== "") {
          if (this.form.pass.match(/([a-z])+/)) {
            ls++;
          }
          if (this.form.pass.match(/([0-9])+/)) {
            ls++;
          }
          if (this.form.pass.match(/([A-Z])+/)) {
            ls++;
          }
          if (
            this.form.pass.match(/([\W])+/) &&
            !this.form.pass.match(/(![\u4E00-\u9FA5])+/)
          ) {
            ls++;
          }
          if (this.form.pass.length < 8) {
            callback(new Error("密码必须为8位及以上"));
            ls = 0;
          }
          if (this.form.pass.match(/([\u4E00-\u9FA5])+/)) {
            callback(new Error("不能包含中文字符"));
            ls = 0;
          }
          switch (ls) {
            case 0:
              this.passwordPercent = 0;
              callback(new Error("数字、小写字母、大写字母以及特殊字符"));
              break;
            case 1:
              this.passwordPercent = 30;
              callback(new Error("数字、小写字母、大写字母以及特殊字符"));
              break;
            case 2:
              this.passwordPercent = 60;
              callback(new Error("数字、小写字母、大写字母以及特殊字符"));
              break;
            case 3:
            case 4:
              this.passwordPercent = 100;
              break;
            default:
              this.passwordPercent = 0;
              break;
          }
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else if (value.match(/([\u4E00-\u9FA5])+/)) {
        callback(new Error("不能包含中文字符"));
      } else if (value.match(/([\W])+/)) {
        callback(new Error("不能包含符号"));
      } else {
        this.$axios
          .post(this.apiuri + "voidusername", {
            type: "account",
            username: this.form.username,
          })
          .then(function (response) {
            let data = response.data;
            if (data.status == 200) {
              callback(new Error("当前用户名已被使用"));
            } else {
              callback();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    var validatePhone = (rule, value, callback) => {
      let region = this.form.region;
      if (this.form.phone === "") {
        callback(new Error("请输入手机号"));
      } else if (
        region === "+86" &&
        !this.form.phone.match(/^1[3456789]\d{9}$/)
      ) {
        callback(new Error("请输入正确的手机号"));
      } else if (region === "+81" && !this.form.phone.match(/^0[789]0\d{8}$/)) {
        callback(new Error("有効な携帯電話番号を入力して下さい"));
      } else {
        this.$axios
          .post(this.apiuri + "voidphone", {
            type: "account",
            region: region,
            phone: this.$utils.encryptv2(this.form.phone, this.aeskey),
          })
          .then(function (response) {
            let data = response.data;
            if (data.status == 200) {
              if (region === "+86") callback(new Error("此手机号已被使用"));
              if (region === "+81")
                callback(new Error("この携帯電話番号はすでに使用されています"));
            } else {
              callback();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    var validateCaptcha = (rule, value, callback) => {
      if (this.form.captchacode === "") {
        callback(new Error("请输入图形验证码"));
      }
      callback();
    };
    return {
      form: {
        region: "+86",
        phone: "",
        username: "",
        pass: "",
        checkPass: "",
        captchacode: "",
        smscode: "",
      },
      regionlist: [
        {
          region: "+1",
          text: "America And Canada",
          disable: true,
        },
        {
          region: "+86",
          text: "中国",
          disable: false,
        },
        {
          region: "+81",
          text: "日本",
          disable: false,
        },
        {
          region: "+34",
          text: "España",
          disable: true,
        },
      ],
      smssendtime: 0,
      smssendstatus: 0,
      smssend: {
        hash: "",
        time: "",
        overdue: 0,
      },
      captcha: {
        base64: "",
        md5: "",
        time: 0,
      },
      passwordPercent: "",
      captchaloading: true,
      sendsmsloading: false,
      sendsmscoutdown: 60,
      sendsmstimer: null,
      regsuccess: false,
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        username: [{ validator: validateUsername, trigger: "blur" }],
        phonenumber: [{ validator: validatePhone, trigger: "blur" }],
        captcha: [{ validator: validateCaptcha, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    onSubmit() {
      var that = this;
      that.$refs["form"].validate((valid) => {
        if (valid) {
          that.$axios
            .post(that.apiuri + "register", {
              type: "account",
              route: "register",
              region: that.form.region,
              phone: that.$utils.encryptv2(that.form.phone, that.aeskey),
              username: that.form.username,
              code: that.$utils.encryptv2(that.form.smscode, that.aeskey),
              pass: that.$utils.encryptv2(that.form.pass, that.aeskey),
            })
            .then(function (response) {
              let data = response.data;
              if (data.status == 200) {
                that.$message({
                  message: "注册成功",
                  type: "success",
                });
                that.$refs["form"].resetFields();
                that.form.phone = "";
                that.form.smscode = "";
                that.form.captchacode = "";
                that.regsuccess = true;
                // that.$message({
                //   message: "3秒后将为您转跳",
                //   type: "success",
                // });
                // setTimeout(function () {
                //   if (
                //     document.referrer &&
                //     document.referrer != window.location.href
                //   ) {
                //     window.location.href = document.referrer;
                //   } else {
                //     window.location.href = "https://aic.xin/";
                //   }
                // }, 3000);
              } else {
                that.$message.error(data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    regsuccessbutton() {
      if (location.href.indexOf("?") === -1) {
        window.location.href = "https://aic.xin/";
      } else {
        let name = "ref";
        let params = new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)")
          .exec(location.href)[1]
          .replace(/\+/g, "%20");
        if (params) {
          window.location.href = params;
        } else {
          window.location.href = "https://aic.xin/";
        }
      }
    },
    passwordPercentFormat(percentage) {
      return percentage == 30
        ? "弱"
        : percentage == 60
        ? "中"
        : percentage == 100
        ? "强"
        : "弱";
    },
    pwdColorF(percentage) {
      return percentage == 30
        ? "#FF5340"
        : percentage == 60
        ? "#FFB640"
        : percentage == 100
        ? "#25DC1B"
        : "#FF5340";
    },
    getCaptcha() {
      var that = this;
      that.captchaloading = true;
      that.$axios
        .get("https://api.aicoiot.com/captcha.php")
        .then(function (response) {
          that.captchaloading = false;
          // console.log(response.data.data)
          that.captcha = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendSmsCode() {
      var that = this;
      // let time = parseInt(new Date().getTime() / 1000) + '';
      if (that.$md5(that.form.captchacode) === that.captcha.md5) {
        that.$refs["form"].validate((valid) => {
          if (valid) {
            that.sendsmsloading = true;
            that.$axios
              .post(that.apiuri + "getsmscode", {
                type: "account",
                route: "register",
                region: that.form.region,
                phone: that.$utils.encryptv2(that.form.phone, that.aeskey),
              })
              .then(function (response) {
                let data = response.data;
                that.sendsmsloading = false;
                if (data.status == 200) {
                  that.smssendstatus = true;
                  if (data.msg) {
                    that.$message({
                      message: data.msg,
                      type: "success",
                    });
                  } else {
                    that.$message({
                      message: "短信发送成功",
                      type: "success",
                    });
                  }

                  that.sendsmstimer = setInterval(() => {
                    that.sendsmscoutdown--;
                    if (that.sendsmscoutdown === 0) {
                      clearInterval(that.sendsmstimer);
                      that.smssendstatus = false;
                    }
                  }, 1000);
                } else {
                  that.$message.error(data.msg);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
      } else {
        that.$message.error("图形验证码错误，请重试，注意区分大小写");
        // console.log(that.$md5(that.form.captchacode), that.captcha.md5);
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 75px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.main {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  transition: 0.2s;
  padding: 30px;
}
@media (max-width: 992px) {
  .main {
    padding: 5px;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #ffffff;
}
.header {
  text-align: center;
  width: 100%;
  z-index: 100;
  position: fixed;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  color: #fff;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.footer {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #dcdfe6;
  margin-top: 120px;
  padding: 15px;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.footer p {
  margin-block-end: 8px;
  margin-block-start: 8px;
}
</style>
