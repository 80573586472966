import Vue from 'vue'
import App from './App.vue'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import Utils from './utils'
const Base64 = require('js-base64').Base64
import md5 from 'js-md5';

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.apiuri = "https://api.aicoiot.com/accoutapi/"
Vue.prototype.version = process.env.version
Vue.prototype.aeskey = "ouUdvj3JLgVVX4UqrJCmo5gcXz36pxFi"
Vue.prototype.$utils = Utils;
Vue.prototype.Base64 = Base64;
Vue.prototype.$md5 = md5;

Vue.use(ElementUI);

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
